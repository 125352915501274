import React from "react";
import Helmet from 'react-helmet';

class IndexPage extends React.Component {
  render() {
    return (
      <Helmet>
        <meta http-equiv="refresh" content={`0;url=/en/`} />
      </Helmet>
    );
  }
}

export default IndexPage;
